/*
 * @Descripttion:
 * @version:
 * @Author: congsir
 * @Date: 2023-04-11 09:38:11
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-04-14 15:58:29
 */
import {
  RegFinancialStatementList,
  ReqFinancialStatementClass,
} from '@/model/customerManagement/financialStatement'
import { $axios } from '../config/axios'
/**
 * @description:上市企业年报分页
 * @param {ReqFinancialStatementClass} data
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=9540
 */
export const PageStatementFinancial = (
  data: ReqFinancialStatementClass
): Promise<RegFinancialStatementList> => {
  return $axios.post('api/bcic/customer/financial/statement/page', data)
}

/**
 * @description:上市企业年报添加关注信息
 * @param {string} id
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=9544
 */
export const AddFollowStatementFinancial = (id: string): Promise<unknown> => {
  return $axios.post('api/bcic/customer/financial/statement/follow/add', {}, { params: { id } })
}

/**
 * @description:上市公司年报取消关注信息
 * @param {string} id
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=9549
 */
export const CancelFollowStatementFinancial = (id: string): Promise<unknown> => {
  return $axios.delete('api/bcic/customer/financial/statement/follow/cancel', { params: { id } })
}

/**
 * @description:刷新年报信息
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=9917
 */
export const RefreshFinancial = (): Promise<unknown> => {
  return $axios.post('api/bcic/customer/financial', {})
}
