import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60ecdb54"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { style: { color: '#13c2c2', padding: '0 3px' } }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormItem = _resolveComponent("FormItem")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_editButton = _resolveComponent("editButton")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_yTable = _resolveComponent("yTable")!
  const _component_Drawer = _resolveComponent("Drawer")!
  const _component_listLayout = _resolveComponent("listLayout")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_listLayout, {
      num: _ctx.selectedRowKeys.length,
      onEmpty: _cache[4] || (_cache[4] = ($event: any) => (_ctx.selectedRowKeys.length = 0))
    }, {
      selectList: _withCtx(() => [
        _createVNode(_component_a_form, {
          layout: "inline",
          model: _ctx.selectFrom.data,
          "label-col": { style: { width: '110px' } }
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formItemList, (item) => {
              return (_openBlock(), _createBlock(_component_a_form_item, {
                key: item.prop,
                label: item.label,
                name: item.prop
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormItem, {
                    value: _ctx.selectFrom.data[item.prop],
                    "onUpdate:value": ($event: any) => ((_ctx.selectFrom.data[item.prop]) = $event),
                    formItemContent: item
                  }, null, 8, ["value", "onUpdate:value", "formItemContent"])
                ]),
                _: 2
              }, 1032, ["label", "name"]))
            }), 128)),
            _createVNode(_component_a_form_item, {
              class: "button",
              label: " ",
              colon: false
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: _ctx.selectProduct
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("查询")
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_a_button, { onClick: _ctx.reset }, {
                  default: _withCtx(() => [
                    _createTextVNode("重置")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      buttonList: _withCtx(() => [
        _createVNode(_component_editButton, {
          icon: _ctx.buttonObj['bcic-customer-financial'],
          text: "刷新",
          type: "primary",
          onClick: _ctx.refresh
        }, null, 8, ["icon", "onClick"]),
        _createElementVNode("span", {
          class: "iconfont icon-lieshezhi pointer",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isShowDrawer = true))
        })
      ]),
      table: _withCtx(() => [
        _createVNode(_component_yTable, {
          dataSource: _ctx.dataSource,
          columns: _ctx.columns,
          selectedRowKeys: _ctx.selectedRowKeys,
          "onUpdate:selectedRowKeys": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedRowKeys) = $event)),
          currentConfigure: _ctx.currentConfigure,
          rowKey: 'id'
        }, {
          bodyCell: _withCtx(({ column, record }) => [
            (column.dataIndex === 'reportTitle')
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: "name",
                  onClick: ($event: any) => (_ctx.goDetail(record))
                }, _toDisplayString(record.reportTitle), 9, _hoisted_1))
              : (column.dataIndex === 'operation')
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    (_ctx.buttonObj['bcic-customer-financial-statement-follow-add'])
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: "edit",
                          style: _normalizeStyle({
                    color: record.isFollow === 1 ? '#f96868' : '#13c2c2',
                    cursor: 'pointer',
                  }),
                          onClick: ($event: any) => (_ctx.follow(record))
                        }, _toDisplayString(record.isFollow === 1 ? '取消关注' : '关注'), 13, _hoisted_3))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", {
                      class: "edit",
                      onClick: ($event: any) => (_ctx.goDetail(record))
                    }, "详情", 8, _hoisted_4)
                  ]))
                : (column.dataIndex === 'isFollow')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createElementVNode("i", {
                        class: "iconfont icon-xinhao",
                        style: _normalizeStyle({
                  'font-size': '10px',
                  color: record.isFollow === 1 ? '#13c2c2' : '#f96868',
                  'margin-right': '5px',
                })
                      }, null, 4),
                      _createTextVNode(" " + _toDisplayString(record.isFollow === 1 ? '已关注' : '未关注'), 1)
                    ], 64))
                  : _createCommentVNode("", true)
          ]),
          footerLeft: _withCtx(() => [
            _createVNode(_component_a_checkbox, {
              checked: _ctx.dataSource.length === _ctx.selectedRowKeys.length && _ctx.dataSource.length !== 0,
              style: { 'margin-left': '20px' },
              "onUpdate:checked": _ctx.checkedChange
            }, {
              default: _withCtx(() => [
                _createTextVNode("已选择"),
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.selectedRowKeys.length), 1),
                _createTextVNode("项")
              ]),
              _: 1
            }, 8, ["checked", "onUpdate:checked"]),
            _createElementVNode("span", {
              style: { color: '#13c2c2', cursor: 'pointer' },
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedRowKeys.length = 0))
            }, "清空已选择")
          ]),
          _: 1
        }, 8, ["dataSource", "columns", "selectedRowKeys", "currentConfigure"]),
        _createVNode(_component_Drawer, {
          visible: _ctx.isShowDrawer,
          "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isShowDrawer) = $event)),
          "origin-columns": _ctx.columns,
          "page-name": "financialStatement",
          onUpdateColumns: _ctx.updateColumns
        }, null, 8, ["visible", "origin-columns", "onUpdateColumns"])
      ]),
      _: 1
    }, 8, ["num"])
  ]))
}