
import { defineComponent, toRefs, computed, reactive, watch, onMounted, nextTick } from 'vue'
import listLayout from '@/components/listLayout/listLayout.vue'
import { onBeforeRouteUpdate, useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import yTable from '@/components/yTable/index.vue'
import FormItem from '@/components/formItem/index.vue'
import { FormItemListType } from '@/types/comm'
import editButton from '@/components/editButton/index.vue'
import {
  PageStatementFinancial,
  CancelFollowStatementFinancial,
  AddFollowStatementFinancial,
  RefreshFinancial,
} from '@/axios/api/financialStatement'
import {
  FinancialStatementClass,
  ReqFinancialStatementClass,
} from '@/model/customerManagement/financialStatement'
import Drawer from '@/components/Drawer/Drawer.vue'
import { ColumnsTypeData } from '@/components/Drawer/Drawer'
export default defineComponent({
  components: {
    listLayout,
    yTable,
    FormItem,
    editButton,
    Drawer,
  },
  setup() {
    onBeforeRouteUpdate((to, from) => {
      console.log('from.name', from.name, pageData.selectFrom)
      store.commit('setRegForm', [from.name, pageData.selectFrom])
    })
    /**
     * @description: 获取筛选后可展示表头列表
     * @param { Array<ColumnsTypeData> } data 当前所有表头列表
     * @return {*}
     */
    const updateColumns = (value: Array<ColumnsTypeData>) => {
      pageData.loading = true
      pageData.columns = value
      nextTick(() => {
        pageData.loading = false
      })
    }
    let route = useRoute()
    console.log(route.params)
    // pageData.dataSource = []
    //获取store
    const store = useStore()
    const buttonObj = store.getters.getButtonObj
    const pageData = reactive({
      isShowDrawer: false, //列设置弹框展示控制
      selectFrom: new ReqFinancialStatementClass(),
      dataSource: [] as FinancialStatementClass[],
      columns: [
        {
          title: '报告名称',
          dataIndex: 'reportTitle',
          width: 400,
          key: 'reportTitle',
          resizable: true,
        },
        {
          title: '公司名称',
          dataIndex: 'customerName',
          width: 200,
          key: 'customerName',
          resizable: true,
        },
        {
          title: '行业名称',
          dataIndex: 'industryName',
          width: 180,
          key: 'industryName',
          resizable: true,
        },
        {
          title: '类型',
          dataIndex: 'reportType',
          width: 150,
          key: 'reportType',
          resizable: true,
          statusObj: {
            annualReport: '年报',
            prospectus: '招股书',
          },
        },
        {
          title: '发布时间',
          dataIndex: 'releaseTime',
          width: 130,
          key: 'releaseTime',
          resizable: true,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          width: 100,
          fixed: 'right',
          resizable: true,
        },
      ] as ColumnsTypeData[],
      selectedRowKeys: [] as string[],
      loading: false,
      formItemList: [
        {
          type: 'subInput',
          prop: 'industryName',
          placeholder: '请输入行业名称',
          label: '行业名称',
        },
        {
          type: 'subInput',
          prop: 'customerName',
          placeholder: '请输入公司名称',
          label: '公司名称',
        },
        {
          type: 'subInput',
          prop: 'reportTitle',
          placeholder: '请输入报告名称',
          label: '报告名称',
        },
        {
          type: 'subSelect',
          prop: 'reportType',
          placeholder: '请选择类型',
          label: '类型',
          optionList: [
            { label: '年报', value: 'annualReport' },
            { label: '招股书', value: 'prospectus' },
          ],
        },
        {
          type: 'subSelect',
          prop: 'isFollow',
          placeholder: '请选择是否关注',
          label: '是否关注:',
          optionList: [
            { label: '关注', value: 1 },
            { label: '未关注', value: 0 },
          ],
        },
      ] as FormItemListType<FinancialStatementClass>,
    })

    if (store.state.regForm[route.name as string] !== undefined) {
      pageData.selectFrom = JSON.parse(JSON.stringify(store.state.regForm[route.name as string]))
      console.log(store.state.regForm[route.name as string])
    }

    //获取表格信息
    const initeList = () => {
      console.log(pageData.loading, pageData.loading)

      if (pageData.loading) return
      pageData.dataSource = []
      pageData.loading = true
      PageStatementFinancial(pageData.selectFrom)
        .then((res) => {
          pageData.dataSource = res.data || []
          console.log(pageData.dataSource, '-----------')
          pageData.selectFrom.currentPage = res.currentPage as number
          pageData.selectFrom.pageSize = res.pageSize
          pageData.selectFrom.dataCount = res.dataCount
          console.log(res.dataCount, 'res.dataCount')

          pageData.loading = false
        })
        .catch((err) => {
          pageData.loading = false
          message.error(err)
        })
    }

    onMounted(() => {
      console.log('加载了')
    })

    watch(
      () => route.query.name,
      (val) => {
        console.log('出发了')
        // 如果是其他页面跳过来的 修改传参
        if (val) {
          pageData.selectFrom.data.customerName = val as string
          window.history.replaceState(route.name, '', route.path)
        }
        // 调用接口
        initeList()
      },
      {
        immediate: true,
      }
    )
    //重置数据
    const selectProduct = () => {
      pageData.selectFrom.currentPage = 1
      pageData.selectFrom.pageSize = 10
      initeList()
    }

    //传入table的页面配置
    const currentConfigure = computed(() => {
      let page = {
        pageSize: pageData.selectFrom.pageSize,
        currentPage: pageData.selectFrom.currentPage,
        total: pageData.selectFrom.dataCount,
        onChange(page: number, pageSize: number) {
          pageData.selectFrom.pageSize = pageSize
          pageData.selectFrom.currentPage = page
          initeList()
        },
        onShowSizeChange(current: number, size: number) {
          pageData.selectFrom.pageSize = size
          pageData.selectFrom.currentPage = current
          initeList()
        },
      }
      return page
    })

    //搜索
    const search = () => {
      selectProduct()
    }

    //重置
    const reset = () => {
      pageData.selectFrom.data = new FinancialStatementClass()
      selectProduct()
    }

    // 全选事件
    const checkedChange = (checked: boolean) => {
      pageData.selectedRowKeys = checked
        ? (pageData.dataSource.map((item) => item.id) as string[]) || []
        : []
    }

    const follow = (data: FinancialStatementClass) => {
      if (data.isFollow === 1) {
        CancelFollowStatementFinancial(data.id as string)
          .then(() => {
            message.success('取消关注成功')
            data.isFollow = 0
          })
          .catch((err) => {
            message.error(err)
          })
      } else {
        AddFollowStatementFinancial(data.id as string)
          .then(() => {
            message.success('关注成功')
            data.isFollow = 1
          })
          .catch((err) => {
            message.error(err)
          })
      }
    }

    // 跳转详情
    const goDetail = (record: FinancialStatementClass) => {
      // if (record.informationType === 'url') {
      //   window.open(record.url)
      // }
      console.log(record)
      if (record.detail) {
        window.open(record.detail)
      }
    }

    // 刷新接口
    const refresh = () => {
      RefreshFinancial()
        .then(() => {
          message.success('正在刷新，请稍等。')
          initeList()
        })
        .catch((err) => {
          message.error(err)
        })
    }
    return {
      refresh,
      goDetail,
      follow,
      ...toRefs(pageData),
      search,
      reset,
      buttonObj,
      currentConfigure,
      selectProduct,
      checkedChange,
      updateColumns,
    }
  },
})
