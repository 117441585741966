/**
 * 上市公司年报
 */
import { ReqPage } from '@/model/common/reqPage'
export class FinancialStatementClass {
  id: string | undefined // "string //主键"
  reportTitle: string | undefined // 报告标题
  customerName: string | undefined // 公司名称
  parentId: number | undefined //  公司id
  industryName: string | undefined // 行业名称
  reportType: string | undefined // 报告类型;annualReport：年报，prospectus：招股书
  releaseTime: string | undefined // 发布时间
  isDeleted: string | undefined //  	是否删除;undeleted：未删除 deleted：已删除
  createdBy: string | undefined // 创建人
  createdTime: string | undefined //创建时间
  updatedBy: string | undefined // 更新人
  updatedTime: string | undefined // 	更新时间
  detail: string | undefined // 详情
  isFollow: 0 | 1 | undefined // 0：未关注  1：关注
}
export class ReqFinancialStatementClass extends ReqPage {
  data: FinancialStatementClass
  constructor() {
    super()
    this.data = new FinancialStatementClass()
  }
}

export interface RegFinancialStatementList extends ReqPage {
  data: FinancialStatementClass[]
}
